<template>
  <v-container>
    <v-content>
      <v-layout row>
        <v-flex>
          <v-card>
            <w-whiteboard></w-whiteboard>
            <chat-composite :rooms="rooms"></chat-composite>
          </v-card>



        </v-flex>

      </v-layout>
    </v-content>
  </v-container>
</template>

<script>
  export default {
    name: "Chat",
    data: () => ({
      title: "Chat",
      rooms: []
    }),
    created() {
      this.getRooms();
    },
    methods: {
      async getRooms(){
        this.rooms = this.$http.get('/comms/room/all');
      }
    }
  }
</script>

<style scoped lang="scss">




</style>